import * as React from 'react';
import { Layout } from 'antd';
import { ReactFragment } from 'react';

const { Footer, Content } = Layout;

type IProps = {
  children: ReactFragment;
};

export const MyLayout = (props: IProps) => {
  return (
    <React.Fragment>
        <Layout className="layout">
            <Content style={{ padding: '0 50px', backgroundColor:'white' }}>
              {props.children}
            </Content>
            <Footer style={{ textAlign: 'center', fontSize:'14px', color:'#666' }}>© 2020 - Armin & Agoston Software Kft.</Footer>
        </Layout>
    </React.Fragment>
  );
};