import * as React from 'react';
import {Button, Typography} from 'antd';

const {Title} = Typography;

export interface IHomeScreenProps {
}
export default class HomeScreen extends React.Component<IHomeScreenProps> {

  public render() {
    return <div style={{textAlign:'center'}}>
      <img src="/logo.png" alt="logo"/>
      <Title>Armin & Agoston Software Ltd.</Title>
      <Title>Software engineering and IT consultancy</Title>
      <Title level={3}>2161 Csomád Kossuth Lajos út 79., Hungary</Title>
      <p>Tax no: HU27990423</p>
      <p><Button type="primary">Get in touch</Button></p>
    </div>
  }
}
