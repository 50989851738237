import React from 'react';
import './App.less';
import { MyLayout } from './components/layout/MyLayout';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import HomeScreen from './screens/HomeScreen';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <MyLayout>
          <Route exact={true} path="/" component={HomeScreen}/>
          <Redirect to="/" />
        </MyLayout>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
